import { SwitchProps } from 'src/types';
import { Toggle } from './styled';
import { Row } from '../Flex';
import { Text } from '../Typography';

export const Switch: React.FC<SwitchProps> = ({label, ...props}) => {
  
  return (
    <Row width="100%" gap={1.875} align="center">
      <Toggle type='checkbox' {...props} />
      {label && <Text>{label}</Text>}
    </Row>
  );
};