export const medicationForm = {
  id: 'medications',
  title: 'Add Medication',
  fields: [
    {
      id: 'medications',
      title: 'Medications',
      type: 'Table',
      columns: [
        {
          id: 'drugName',
          label: 'Drug Name',
          title: 'Drug Name',
          type: 'Text',
          validation: 'required',
          placeholder: 'Drug Name',
        },
        {
          id: 'dosage',
          title: 'Dosage',
          type: 'Text',
          validation: 'required',
          placeholder: 'Dosage',
        },
        {
          id: 'frequency',
          title: 'Frequency',
          type: 'Text',
          validation: 'required',
          placeholder: 'Frequency',
        },
        {
          id: 'duration',
          title: 'Duration',
          type: 'Text',
          validation: 'required',
          placeholder: 'Duration',
        },
      ],
    },
  ],
};

export const allergiesForm = {
  id: 'allergies',
  title: 'Add Allergies',
  fields: [
    {
      id: 'allergies',
      title: 'Allergies',
      type: 'Table',
      columns: [
        {
          id: 'name',
          title: 'Allergy',
          type: 'Text',
          validation: 'required',
          placeholder: 'New Allergy',
        },
        {
          id: 'level',
          title: 'Level',
          type: 'Select',
          validation: 'required',
          placeholder: 'Select Level',
          options: [
            {
              label: 'Low',
              value: 'Low',
            },
            {
              value: 'Mild',
              label: 'Mild',
            },
            {
              label: 'Critical',
              value: 'Critical',
            },
          ],
        },
      ],
    },
  ],
};

export const bloodInfoForm = {
  id: 'blood_information',
  title: 'Update Blood Information',
  fields: [
    {
      id: 'bloodInfo',
      title: 'Blood Information',
      type: 'Row',
      fields: [
        {
          id: 'bloodType',
          title: 'Blood Group',
          type: 'Select',
          validation: 'required',
          placeholder: 'Select Blood Group',
          options: [
            {
              label: 'O+',
              value: 'O+',
            },
            {
              value: 'O-',
              label: 'O-',
            },
            {
              label: 'AB+',
              value: 'AB+',
            },
            {
              label: 'AB-',
              value: 'AB-',
            },
            {
              label: 'A+',
              value: 'A+',
            },
            {
              label: 'A-',
              value: 'A-',
            },
            {
              label: 'B+',
              value: 'B+',
            },
            {
              label: 'B-',
              value: 'B-',
            },
          ],
        },
        {
          id: 'genoType',
          title: 'Genotype',
          type: 'Select',
          validation: 'required',
          placeholder: 'Select Genotype',
          options: [
            {
              label: 'AA',
              value: 'AA',
            },
            {
              label: 'AC',
              value: 'AC',
            },
            {
              value: 'AS',
              label: 'AS',
            },
            {
              label: 'SC',
              value: 'SC',
            },
            {
              label: 'SS',
              value: 'SS',
            },
          ],
        },
      ],
      // fields: [
      //   {
      //     id: 'bloodType',
      //     title: 'Blood Group',
      //     type: 'Text',
      //     validation: 'required',
      //     placeholder: 'blood group e.g(O+)',
      //   },
      //   {
      //     id: 'genoType',
      //     title: 'Genotype',
      //     type: 'Text',
      //     validation: 'required',
      //     placeholder: 'genotype (e.g. AA)',
      //   },
      // ],
    },
  ],
};

/**
 *
 */
