import moment from 'moment';
import { Column, H6, Text } from 'src/components';
import { useNotification, useToast } from 'src/state';
import { theme } from 'src/utils/variables';
import { EmptyTodo } from 'src/assets/images';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { MARK_ALL_NOTIFICATIONS } from 'src/constants';
import { NotificationCard, NotificationModal } from './styled';

const Notification = () => {
  const { showToast } = useToast();
  const { notifications, setUnreadSize, unreadSize } = useNotification();
  const allNotifications = notifications && notifications.getNotifications;
  const [markAllNotifications, { error }] = useMutation(MARK_ALL_NOTIFICATIONS);

  useEffect(() => {
    if (unreadSize > 0) {
      markAllNotifications({
        onCompleted() {
          setUnreadSize(0);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      showToast(error.message, 'error');
    }
  }, [showToast, error]);

  return (
    <NotificationModal>
      <Column minWidth="20rem" width="100%" height="100%" gap={0.5}>
        <H6 size='md'>Notifications</H6>
        {allNotifications &&
          (allNotifications.length > 0 ? (
            <Column>
              <Column gap={0.5} width="100%">
                {allNotifications.map((notification, idx: number) => (
                  <NotificationCard
                    key={idx}
                    gap={0.625}
                    width="100%"
                    align="flex-start"
                  >
                    <Column gap={0.5}>
                      <Text size="sm" color={theme.black[100]}>
                        {notification.message}
                      </Text>
                      <Text
                        align="right"
                        size="xs"
                        weight="semibold"
                        color={theme.grey[500]}
                      >
                        {moment(notification.createdAt).format(
                          'h:mm a, MMMM Do YYYY',
                        )}
                      </Text>
                    </Column>
                  </NotificationCard>
                ))}
              </Column>
            </Column>
          ) : (
            <Column
              gap={0.5}
              height="100%"
              width="100%"
              justify="center"
              align="center"
            >
              <EmptyTodo />
              <Text size="xs" weight="semibold" color={theme.grey[500]}>
                You have no notifications
              </Text>
            </Column>
          ))}
      </Column>
    </NotificationModal>
  );
};
export default Notification;
