/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { GET_PRODUCTS } from 'src/constants';
import useSearch from 'src/hooks/useSearch';
import { RootState, useAppSelector, useAppDispatch } from 'src/store';
import { InventoryState, OptionProps } from 'src/types';
import { useStaff } from 'src/state';
import { useInventoryApis } from './api';
import { setProducts } from './reducer';

export type CheckedType =
  | Omit<OptionProps, 'checked'>
  | Omit<OptionProps, 'checked'>[];

export interface FilterType {
  name: string;
  key: string;
  type?: 'multi-select' | 'select';
  checked: CheckedType;
  options: Omit<OptionProps, 'checked'>[];
}

export interface FlatFilterType {
  name: string;
  key: string;
  type?: 'multi-select' | 'select';
  checked: Omit<OptionProps, 'checked'>;
}

const alertType: FilterType = {
  name: 'Alert Type',
  key: 'batchStatus',
  type: 'multi-select',
  checked: [] as Omit<OptionProps, 'checked'>[],
  options: [
    {
      label: 'In-Stock',
      value: 'IN_STOCK',
    },
    {
      label: 'Out-of-Stock',
      value: 'OUT_OF_STOCK',
    },
    {
      label: 'Expiring Soon',
      value: 'EXPIRING_SOON',
    },
    {
      label: 'Expired',
      value: 'EXPIRED',
    },
    {
      label: 'Low in Stock',
      value: 'LOW_STOCK',
    },
    {
      label: 'Order placed',
      value: 'ORDER_PLACED',
    },
  ],
};

const otc = {
  name: 'OTC',
  key: 'isOTC',
  checked: {} as Omit<OptionProps, 'checked'>,
  options: [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ],
};

const status = {
  name: 'Status',
  key: 'isArchived',
  checked: {} as Omit<OptionProps, 'checked'>,
  options: [
    {
      label: 'Archived',
      value: 'true',
    },
    {
      label: 'Unarchived',
      value: 'false',
    },
  ],
};

const flattenFilters = (filters: FilterType[]) =>
  filters.reduce((acc, filter) => {
    const { options, ...rest } = filter;
    if (Array.isArray(filter.checked)) {
      filter.checked.map((checked) => {
        if (checked.label?.length > 0)
          acc.push({
            checked,
            key: rest.key,
            name: rest.name,
            type: rest.type,
          });
        return options;
      });
    } else if (filter?.checked?.label?.length > 0) {
      acc.push({
        checked: rest.checked as Omit<OptionProps, 'checked'>,
        key: rest.key,
        name: rest.name,
        type: rest.type,
      });
    }
    return acc;
  }, [] as FlatFilterType[]);

const getFilters = (filters: FilterType[]) =>
  filters.reduce((acc, filter) => {
    if (Array.isArray(filter.checked)) {
      if (filter.checked.length) {
        acc[filter.key] = filter.checked.map((f) => f.value);
      }
    } else if (filter.checked?.label?.length > 0) {
      if (filter.checked.value === 'true') {
        acc[filter.key] = true;
      } else if (filter.checked.value === 'false') {
        acc[filter.key] = false;
      } else {
        acc[filter.key] = filter.checked.value;
      }
    }
    return acc;
  }, {} as Record<string, string | number | boolean | string[]>);

const useInventory = () => {
  const allProducts = useAppSelector((state: RootState) => state.inventory);
  const allProductLength = allProducts?.products;
  const { staff } = useStaff();
  const facilityId = staff.getStaff.facility.id;
  const dispatch = useAppDispatch();
  const limit = 10;

  const { fetchAllProducts, fetchAllProductsLoading, fetchAllProductsError } =
    useInventoryApis();

  const [filteredData, setFilteredData] = useState<InventoryState>(allProducts);
  const [page, setPage] = useState(1);
  const defaultState = [
    { ...alertType },
    { ...otc },
    { ...status },
  ]
  const [filters, setFilters] = useState<FilterType[]>(defaultState);

  const { handleSearchChange, searchLoading, searchValue, searchQuery } =
    useSearch(GET_PRODUCTS, {
      onCompleted: (d: unknown) => {
        const data = d as { getProducts: InventoryState };
        setFilteredData(data?.getProducts);
      },
    });

  const fetchInventory = useCallback(async () => {
    await fetchAllProducts({
      variables: {
        input: {
          page: 1,
          facilityId,
          search: '',
          isArchived: false,
          limit,
        },
      },
      onCompleted(d) {
        const data = d?.getProducts as InventoryState;
        dispatch(setProducts(data));
        setFilteredData(data);
      },
    });
  }, [dispatch, facilityId, fetchAllProducts]);

  useEffect(() => {
    if (!allProductLength) {
      fetchInventory();
    } else {
      setFilteredData(allProducts);
    }
  }, [allProductLength, allProducts, fetchInventory]);

  const filter = (
    searchPage: number,
    currentFilters: { [key: string]: string | number | boolean | string[] },
  ) => {
    searchQuery({
      variables: {
        input: {
          limit,
          facilityId,
          page: searchPage,
          search: searchValue,
          isArchived: false,
          ...currentFilters,
        },
      },
      onCompleted(data) {
        if (!fetchAllProductsError) {
          setFilteredData((prev) => ({
            ...prev,
            ...data?.getProducts,
          }));
        }
      },
    });
  };

  const applyFilters = (newFilter: {
    [key: string]: string | number | boolean | string[];
  }) => {
    setPage(1);
    filter(1, newFilter);
  };

  const removeFilter = (newFilter: {
    [key: string]: string | number | boolean | string[];
  }) => {
    setPage(1);
    filter(1, newFilter);
  };

  const clearFilters = () => {
    setFilteredData(allProducts);
    filter(1, {});
    setFilters([{ ...alertType }, { ...otc }, { ...status }]);
    setPage(1);
  };

  const fetchNewpage = (newPage: number) => {
    filter(newPage, getFilters(filters));
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(
      e,
      {
        limit,
        facilityId,
        page: 1,
        ...getFilters(filters),
      },
      { searchOnEmpty: true },
    );
  };

  return {
    limit,
    searchValue,
    clearFilters,
    applyFilters,
    onSearchChange,
    defaultState,
    isLoading: searchLoading || fetchAllProductsLoading,
    fetchAllProductsLoading,
    fetchAllProductsError,
    searchLoading,
    filteredData,
    page,
    setPage,
    fetchNewpage,
    removeFilter,
    filters,
    setFilters,
    getFilters,
    flattenFilters,
  };
};

export { useInventory };
