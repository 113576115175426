import { BarsIcon, CancelIcon } from 'src/assets/icons';
import { BuildSideBarItems } from 'src/containers/Sidebar/BuildSideBarItems';
import { sidebar } from 'src/configs/sidebar';
import useClickAway from 'src/utils/useClickAway';
import { Button } from '../Button';
import { Header, MenuButton, PopoverContainer } from './styled';
import { SwitchWorkspace } from '..';

interface NavigationDropdownProps {
  expand?: boolean
}

export const NavigationDropdown: React.FC<NavigationDropdownProps> = ({ expand }) => {
  const { ref, isComponentVisible: isOpen, setIsComponentVisible: setIsOpen } = useClickAway(false)
  
  return (
    <>
      <Button variant="text" width="max-content" onClick={() => setIsOpen(prev => !prev)}>
        <BarsIcon style={{ strokeWidth: '0px' }} />
      </Button>

      <PopoverContainer ref={ref} isOpen={isOpen}>
        <Header>
          <MenuButton onClick={() => setIsOpen(false)}>
            <CancelIcon />
          </MenuButton>
        </Header>

        <BuildSideBarItems 
          onClick={() => setIsOpen(false)} 
          config={sidebar} 
          expand 
        />

        <SwitchWorkspace onClick={() => setIsOpen(false)} expand={expand} />
      </PopoverContainer>
    </>
  );
};
