import { Modal } from 'src/containers';
import { ModalChildContainer } from 'src/containers/Modal/styled';
import { Column, Paragraph, Row } from 'src/components';
import styled from 'styled-components';
import { theme, font } from 'src/utils/variables';
import { PillButtonType } from 'src/types';
import { SearchIcon } from 'src/assets/icons';

export const GlobalSearchModal = styled(Modal)`
  ${ModalChildContainer} {
    min-height: 80vh;
    @media (max-width: 960px) {
      min-width: 100%;
    }
  }
`;

export const ModalTitle = styled.h5`
  font-size: 1.25rem;
  margin-bottom: 1.7rem;
  font-weight: ${font.weights.bold};
  color: ${theme.black[200]};
  @media (max-width: 960px) {
    font-size: ${font.sizes.md};
  }
`;

export const PillButton = styled.button<PillButtonType>`
  background: ${({ active }) =>
    !active ? theme.grey[100] : theme.secondary[200]};
  border: 1px solid
    ${({ active }) => (!active ? '#E5E5E5' : theme.secondary[300])};
  color: ${({ active }) => (!active ? theme.grey[600] : theme.secondary[600])};
  font-weight: ${font.weights.semibold};
  display: ${(props) => (props.show ? 'flex' : 'none')};
  cursor: pointer;
  padding: 0.44rem 1.25rem;
  border-radius: 0.625rem;
  align-items: center;
  svg {
    margin-right: 0.25rem;
  }
`;

export const RowHeader = styled(Paragraph)`
  margin: 0;
  margin-top: 2.375rem;
  display: flex;
  align-items: center;
  color: ${theme.grey[700]};
`;

export const RecentText = styled(Paragraph)`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 0.625rem;
  }
`;

export const SearchContainer = styled(Column)`
  position: relative;
  z-index: 99;
`;

export const SearchResultContainer = styled(Column)<{ isVisible: boolean }>`
  position: absolute;
  top: 2.5rem;
  padding: 1.5rem 1rem;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`;

export const SearchLogo = styled(SearchIcon)`
  stroke: ${theme.grey[400]};
`;

export const ResultTextRow = styled(Row)`
  cursor: pointer;
  padding: 0.25rem;
  &:hover {
    background-color: ${theme.grey[100]};
    color: ${theme.black[100]};
  }
`;

export const WrappingContainer = styled(Row)`
  flex-wrap: wrap;
`;
