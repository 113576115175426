import { RightArrow } from 'src/assets/icons';
import {
  Row,
  IconButton,
  H6,
  Text,
  Column,
  Avatar,
  Button,
} from 'src/components';
import { DropdownTrigger } from 'src/components/Dropdown/styled';
import { font, theme } from 'src/utils/variables';
import styled, { css } from 'styled-components';

export const PatientName = styled(H6)`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  @media (max-width: 960px) {
    font-size: ${font.sizes.base};
  }
`;

export const ArrowDownIcon = styled(RightArrow)`
  stroke: #9b9b9b;
  color: #9b9b9b;
  transform: rotate(90deg);
`;

export const ArrowDownButton = styled.div`
  display: flex;
  transform: rotate(90deg);
  // padding: 0 0.625rem;
`;

export const PatientDetails = styled(Text)`
  margin: 0;
  font-size: ${font.sizes.sm};
  width: max-content;
  @media (max-width: 960px) {
    font-size: ${font.sizes.xs};
  }
`;

export const Section = styled(Column)`
  overflow-y: auto;
  flex-grow: 1;
  max-height: calc(100vh - 8rem);
  align-self: flex-start;
  border: 1px solid rgba(237, 233, 229, 0.2);
  box-shadow: 0px 0px 24px 1px rgba(86, 77, 66, 0.03);
  border-radius: 10px;
  @media (max-width: 960px) {
    max-height: calc(100vh - 18rem);
    overflow-y: auto;
  }
`;

export const MobileTabSection = styled(Section)`
  @media (max-width: 960px) {
    max-height: 100%;
    overflow-y: auto;
  }
`;

export const PatientAvatar = styled(Avatar)`
  padding: 0rem;
`;

export const Profile = styled(Row)`
  padding: 1rem 0rem 1rem 1rem;
  align-items: center;
  max-width: 100%;
  ${DropdownTrigger} {
    border-radius: 50%;
    width: 2.125rem;
    height: 2.125rem;
    background-color: ${theme.grey[50]};
    padding: 0;
    justify-content: center;
    & > svg {
      stroke-width: 0;
    }
  }
  @media (max-width: 600px) {
    padding-left: 0.75rem;
  }
  @media (min-width: 960px) {
    ${Row}:last-child {
      width: max-content;
      margin-left: auto;
      align-self: flex-start;
    }
  }
`;

export const PaddingContainer = styled(Column)`
  padding: 1rem;
`;

export const PaddingRowContainer = styled(Row)`
  padding: 1rem 0;
  @media (max-width: 960px) {
    padding: 0 1rem;
  }
`;

export const SectionHeader = styled(Row)`
  gap: 0.5rem;
  align-items: center;
  background-color: ${theme.primary[100]};
  border-radius: 0.5rem;
  padding: 1rem;
  color: ${theme.primary[500]};
  font-weight: ${font.weights.semibold};
  @media (max-width: 960px) {
    gap: 0.25rem;
    font-size: ${font.sizes.sm};
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  button {
    padding: 0;
  }
`;

export const EditButton = styled(IconButton)`
  justify-items: flex-end;
  align-self: center;
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  margin-right: 1rem;
`;

export const WrappingContainer = styled(Row)`
  flex-flow: row wrap;
`;

export const SectionColumn = styled(Column)`
  padding: 0rem 1rem;
  font-size: 0.875rem;
`;

export const SectionText = styled(Text)`
  width: 50%;
  text-align: left;
  word-wrap: break-word;
`;

export const RoundButton = styled(IconButton)`
  border-radius: 50%;
  width: 2.125rem;
  height: 2.125rem;
  path {
    stroke-width: 0;
    
  }
  @media (max-width: 960px) {
    width: 1.5rem;
    height: 1.5rem;
    & + span {
      font-size: ${font.sizes.sm};
    }
    svg {
        width: 0.5rem;
        height: 0.5rem;
      }    
    }
  }
`;

export const NoPaddingButton = styled(Button)`
  padding: 0;
`;

export const RoundIcon = styled(Row)`
  background-color: ${theme.primary[200]};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2.125rem;
  height: 2.125rem;
  path {
    stroke-width: 0;
  }
  @media (max-width: 960px) {
    width: 1.5rem;
    height: 1.5rem;
    & + span {
      font-size: ${font.sizes.sm};
    }
    svg {
        width: 0.5rem;
        height: 0.5rem;
      }    
    }
  }
`;

export const TabHeaderIndicatorButton = styled(IconButton)<{
  active?: boolean;
}>`
  background: transparent;
	width: 1rem;
	height: 1.25rem;
  svg,
  path {
    fill: ${(props) => (props.active ? theme.secondary[700] : theme.grey[700])};
    stroke-width: 0.25;
  },
`;

export const PaddingBottomContainer = styled(Row)`
  padding-bottom: 0.75rem;
`;
export const MarginBottomContainer = styled(Row)`
  margin-bottom: 0.75rem;
`;

export const EllipsisButton = styled(IconButton)`
  height: auto;
  align-self: stretch;
`;

export const EllipsisButtonWrapper = styled(Row)`
  align-self: stretch;
`;

export const TabIndicatorText = styled(Text)`
  margin: 0rem;
  width: 100%;
`;

export const FormsContainer = styled(Column)`
  overflow-y: auto;
  overflow-x: hidden;
`;

export const FormWrapper = styled(Row)`
  flex-wrap: wrap;
  overflow-x: hidden;
`;

export const ClickableFormPreview = styled(Column)<{
  isClicked?: boolean;
}>`
  ${({ isClicked }) => {
    return (
      isClicked &&
      css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: brightness(0.75);
        &::after {
          content: '';
          position: absolute;
          width: 3rem;
          height: 3rem;
          z-index: 10;
          border: 0.25rem solid transparent;
          border-top-color: ${theme.grey[300]};
          border-radius: 50%;
        }
      `
    );
  }};
  background: rgba(246, 246, 246, 1);
  border-radius: 0.625rem;
  padding: 1.25rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  max-width: max-content;
  position: relative;
  ${Row} {
    position: absolute;
    top: 0.4375rem;
    right: 0.4375rem;
    width: max-content;
  }
  ${DropdownTrigger} {
    width: 1.25rem;
    height: 1.25rem;
    background-color: rgba(23, 23, 23, 0.3);
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > svg {
      stroke-width: 0;
      margin-right: 2px;
    }
  }
`;

export const VerticalLineFaded = styled.div<{
  marginLeft?: number;
}>`
  padding: 25% 0;
  margin-right: 1.5rem;
  margin-left: ${(props) => props.marginLeft}rem;
  margin-top: 1.3rem;
  border-left: 1px dashed ${(props) => props.color};
`;
export const VerticalLineSharp = styled.div`
  position: absolute;
  height: 100%;
  left: -1.56rem;
  top: 0.625rem;
  border-left: 1px dashed ${(props) => props.color};
`;

export const TimelineIndicator = styled.div`
  height: 0.94rem;
  width: 0.94rem;
  display: block;
  border-radius: 50%;
  border: 0.3125rem solid ${(props) => props.color};
  position: absolute;
  left: -2rem;
  top: 0.625rem;
  background: #fff;
  box-shadow: 0px 0px 5px 1px rgba(135, 107, 101, 0.19);
`;

export const JourneyCard = styled(Column)`
  background: #ffffff;
  box-shadow: 3px 8px 30px 5px rgba(0, 0, 0, 0.04);
  border-radius: 0.625rem;
  border: 1px solid ${theme.accent[300]};
  padding: 1.25rem;
`;

export const TimlineCard = styled(Column)`
  background: #ffffff;
  box-shadow: 3px 8px 30px 5px rgba(0, 0, 0, 0.04);
  border-radius: 0.3125rem;
  padding: 1.25rem;
`;

export const TimlineScrollContainer = styled(Column)`
  height: calc(100vh - 25rem);
  padding-bottom: 2.5rem;
  padding-left: 0.5rem;
  padding-right: 1rem;
  margin-top: 4.69rem;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 960px) {
    max-height: calc(100vh - 32rem);
  }
`;

export const Timline = styled.div`
  position: relative;
  width: 100%;
  height: 0.5rem;
  background-color: 
  }
`;

export const TimelineDate = styled(Text)`
  background: #ffffff;
  box-shadow: 0px 0px 55px 4px rgb(118 118 118 / 12%);
  border-radius: 0.31rem;
  display: flex;
  padding: 0.625rem;
  & svg {
    margin-right: 0.31rem;
  }
`;

export const TimelineTag = styled(Text)<{
  marginLeft?: number;
  marginRight?: number;
}>`
  display: flex;
  margin-bottom: 0;
  margin-left: ${(props) => `${props.marginLeft || 0}rem`};
  margin-right: ${(props) => `${props.marginRight || 0}rem`};
  font-weight: 600;
  font-size: 0.875rem;
  color: ${theme.grey[700]};
  & svg {
    margin-right: 0.31rem;
    min-width: 1rem;
    min-height: 1rem;
  }
`;
export const FixedTabs = styled(Row)`
  white-space: nowrap;
  position: sticky;
  top: -1px;
  z-index: 100;
  background-color: ${theme.white[100]};
  & > div {
    justify-content: center;
    gap: 2rem;
    @media (max-width: 960px) {
      gap: 1.25rem;
    }
  }
  ${Row} {
    justify-content: center;
    gap: 0.5rem;
    @media (max-width: 960px) {
      span {
        font-size: ${font.sizes.sm};
      }
    }
  }
  @media (max-width: 960px) {
    overflow-x: auto;
    height: inherit;
    width: 100%;
  }
`;

export const RecordWrapper = styled(Column)`
  position: relative;
  // max-width: 37.5rem;
`;

export const ContentWrapper = styled(Column)`
  margin-top: 5rem;
`;

export const PillText = styled(Text)`
  background: #f9f9f9;
  border-radius: 0.2rem;
  padding: 0.18rem 0.4rem;
  margin-right: 0.625rem;
`;

export const ColumnCard = styled(Column)`
  background: #f9f9f9;
  border-radius: 0.2rem;
  padding: 1rem;
  margin-right: 0.625rem;
`;
export const RowCard = styled(Row)`
  background: #f9f9f9;
  border-radius: 0.2rem;
  padding: 1rem;
  // width: max-content;
`;
export const EllipseContainer = styled(Row)`
  background-color: ${theme.grey[300]};
  width: 2.25rem;
  height: 2.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  path {
    fill: ${theme.grey[400]};
    width: 0.75rem;
    height: 0.75rem;
  }
`;

export const EllipseStrokeContainer = styled(Row)`
  background-color: ${theme.grey[300]};
  width: 2.25rem;
  height: 2.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  path {
    stroke: ${theme.grey[400]};
    width: 0.75rem;
    height: 0.75rem;
  }
`;

export const OverflowContainer = styled(Column)`
  max-height: calc(100vh - 14rem);
  @media (max-width: 960px) {
    padding: 0 0.25rem;
    max-height: calc(100vh - 24rem);
    overflow-y: auto;
  }
`;

export const TabIconButton = styled(IconButton)`
  min-width: 3.125rem;
  min-height: 3rem;
  svg {
    stroke-width: 0;
  }
`;

export const JourneyButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 0.9375rem;
  border-radius: 0.9375rem;
  cursor: pointer;
  border: 1px solid ${theme.accent[400]};
  background: ${theme.accent[100]};
`;
