/* eslint-disable no-prototype-builtins */
import { ModStylesUtil } from 'src/types';
import { css } from 'styled-components';

const getValue = (val: number | string | undefined) => {
  if (typeof val === 'string') {
    return `${val};`;
  }
  return `${val}rem;`;
};

export const injectModStyles = (modStyles: ModStylesUtil) => {
  const {
    ml,
    ma,
    mb,
    mr,
    mt,
    mx,
    my,
    pa,
    pb,
    pl,
    pr,
    pt,
    px,
    py,
    minW,
    maxW,
    minH,
    maxH,
    bg,
    br,
    opacity,
    overflow,
    overflowX,
    overflowY,
    textTransform,
    fontSize,
    dropShadow,
    boxShadow,
    lineHeight,
    color,
    border,
  } = modStyles;
  return css`
    ${modStyles.hasOwnProperty('ma') && `margin: ${getValue(ma)}`}
    ${modStyles.hasOwnProperty('ml') && `margin-left: ${getValue(ml)};`}
  ${modStyles.hasOwnProperty('mt') && `margin-top: ${getValue(mt)};`}
  ${modStyles.hasOwnProperty('mr') && `margin-right: ${getValue(mr)};`}
  ${modStyles.hasOwnProperty('mb') && `margin-bottom: ${getValue(mb)};`}
  ${modStyles.hasOwnProperty('mx') && `margin-inline: ${getValue(mx)};`}
  ${modStyles.hasOwnProperty('my') && `margin-block: ${getValue(my)};`}
  ${modStyles.hasOwnProperty('pa') && `padding: ${getValue(pa)};`}
  ${modStyles.hasOwnProperty('pl') && `padding-left: ${getValue(pl)};`}
  ${modStyles.hasOwnProperty('pt') && `padding-top: ${getValue(pt)};`}
  ${modStyles.hasOwnProperty('pr') && `padding-right: ${getValue(pr)};`}
  ${modStyles.hasOwnProperty('pb') && `padding-bottom: ${getValue(pb)};`}
  ${modStyles.hasOwnProperty('px') && `padding-inline: ${getValue(px)};`}
  ${modStyles.hasOwnProperty('py') && `padding-block: ${getValue(py)};`}
  ${modStyles.hasOwnProperty('minW') && `min-width: ${getValue(minW)};`}
  ${modStyles.hasOwnProperty('maxW') && `max-width: ${getValue(maxW)};`}
  ${modStyles.hasOwnProperty('minH') && `min-height: ${getValue(minH)};`}
  ${modStyles.hasOwnProperty('maxH') && `max-height: ${getValue(maxH)};`}
  ${modStyles.hasOwnProperty('br') && `border-radius: ${getValue(br)};`}
  ${modStyles.hasOwnProperty('bg') && `background-color: ${bg};`}
  ${modStyles.hasOwnProperty('opacity') && `opacity: ${opacity};`}
  ${modStyles.hasOwnProperty('textTransfrom') &&
    `text-transform: ${textTransform};`}
  ${modStyles.hasOwnProperty('overflow') && `overflow: ${overflow};`}
  ${modStyles.hasOwnProperty('overflowX') && `overflow-x: ${overflowX};`}
  ${modStyles.hasOwnProperty('overflowY') && `overflow-y: ${overflowY};`}
  ${modStyles.hasOwnProperty('fontSize') && `font-size: ${fontSize};`}
  ${modStyles.hasOwnProperty('dropShadow') && `drop-shadow: ${dropShadow};`}
  ${modStyles.hasOwnProperty('boxShadow') && `box-shadow: ${boxShadow};`}
  ${modStyles.hasOwnProperty('lineHeight') && `line-height: ${lineHeight};`}
  ${modStyles.hasOwnProperty('color') && `color: ${color};`}
  ${modStyles.hasOwnProperty('border') && `border: ${border};`}
  `;
};
