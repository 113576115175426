import { HR, SidebarItems, SubGroupItemsWrapper, SwitchAccountButton } from 'src/containers/Sidebar/styled';
import { useAuth, useStaff } from 'src/state';
import { generateAvatar } from 'src/utils';
import { ArrowForwardIcon, BusinessIcon, LogoutIcon } from 'src/assets/icons';
import _ from 'lodash';
import { SideBarButtonItem } from 'src/containers/Sidebar/SideBarButtonItem';
import { SidebarConfig } from 'src/types';
import useClickAway from 'src/utils/useClickAway';
import { useEffect } from 'react';
import useMediaQuery from 'src/utils/useMediaQuery';
import { Avatar } from '../Avatar';
import { Column, Row } from '../Flex';
import { Text } from '../Typography';

interface SwitchWorkspaceProps {
  expand?: boolean
  onClick?: VoidFunction
}

export const SwitchWorkspace: React.FC<SwitchWorkspaceProps> = ({ expand, onClick }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useClickAway(false);
  const { logOut } = useAuth();
  const { staff } = useStaff();
  const isMobile = !useMediaQuery('(min-width: 960px)');


  useEffect(() => {
    if (!expand) {
      setIsComponentVisible(false)
    }
  }, [expand, setIsComponentVisible])


  
  const config: SidebarConfig[] = [
    {
      title: 'Log Out',
      icon: LogoutIcon,
      action: logOut,
      noStroke: true
    },
    {
      title: 'Switch WorkSpace',
      icon: BusinessIcon,
      path: "#switch-workspace",
      noFill: true,
      noStroke: true
    },
  ];


  return (
    <Column width="100%" gap={0.5} align="center" ref={ref}>
      <HR />
      <SwitchAccountButton onClick={() => setIsComponentVisible(!isComponentVisible)}>
        <>
          {staff && staff.getStaff && (
            <Avatar
              size={40}
              src={
                staff.getStaff.facility.organisation.logo ||
                generateAvatar(staff.getStaff.facility.organisation.name)
              }
            />
          )}
          <Column width="max-content" justify="center">
            <Text size="sm" modStyles={{ my: 0}}>
              {_.truncate(staff?.getStaff?.user?.fullName, { length: 12 })}
            </Text>
            <Text size="xxs" modStyles={{ my: 0}}>
              {_.truncate(staff?.getStaff?.user?.emails[0], { length: 16 })}
            </Text>
          </Column>
          <Row justify='center' align="center" width="1.25rem" height="1.25rem">
            <ArrowForwardIcon style={{ rotate: (isComponentVisible && isMobile) ? "90deg" : 'none' }} />
          </Row>
        </>
      </SwitchAccountButton>
      {isComponentVisible && (
        <SubGroupItemsWrapper>
          {config?.map((item, index) => {
            return (
              <SidebarItems key={`sub-menu-item-${index}`}>
                <SideBarButtonItem 
                  item={item} 
                  onClick={() => {
                    setIsComponentVisible(false)
                    onClick?.()
                  }}
                />
              </SidebarItems>
            )
          })}
        </SubGroupItemsWrapper>
      )}
    </Column>
  );
};
