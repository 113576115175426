import { useState, useEffect, useRef, useCallback } from 'react';

export default function useClickAway(
    initialIsVisible: boolean,
    onClose?: () => void
) {
    const [isComponentVisible, setIsComponentVisible] = useState<boolean>(initialIsVisible);
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsComponentVisible(false);
            if (typeof onClose === 'function') {
                onClose();
            }
        }
    }, [onClose]);

    useEffect(() => {
        setIsComponentVisible(initialIsVisible);
    }, [initialIsVisible]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const hide = useCallback(() => {
        setIsComponentVisible(false);
        if (typeof onClose === 'function') {
            onClose();
        }
    }, [onClose]);

    return { ref, isComponentVisible, setIsComponentVisible, hide };
}

