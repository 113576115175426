import { Row, Button, Text } from 'src/components';
import { useStaff } from 'src/state';
import { theme } from 'src/utils';
import { CheckInIcon, DisableIcon } from 'src/assets/icons';
import { APPOINTMENT_STATUS } from 'src/constants';
import Skeleton from 'react-loading-skeleton';
import useMediaQuery from 'src/utils/useMediaQuery';
import { AppointmentText, PaddingWrapper} from './styled';
import { AppointmentActionsType } from '.';


const AppointmentActions: React.FC<AppointmentActionsType> = ({
  status,
  loading,
  isExpired,
  createdBy,
  handleCancel,
  handleCheckIn,
  handleCheckOut,
  patientLoading,
  checkOutLoading,
  appointmentLoading,
  cancelAppointmentLoading
}) => {
  const { hasPermission } = useStaff();
  const { user, staffGroup } = createdBy || {};
  const isMobile = !useMediaQuery('(min-width: 960px)');
  const { ONGOING, CANCELLED, COMPLETED } = APPOINTMENT_STATUS
  const isOngoingAppointment = status === ONGOING;
  const isCancelledAppointment = status === CANCELLED;
  const isCompletedAppointment = status === COMPLETED;


  const Actions = () => {

    if (isCancelledAppointment || isCompletedAppointment) return (
      <Text color={theme[ isCancelledAppointment ? 'red' : 'green'][700]} weight="bold">
        {`Appointment ${isCancelledAppointment ? "Cancelled" : "Completed"}`}
      </Text>
    )

    return (
      <Row align="center" gap={0.625}>
        {hasPermission(isOngoingAppointment ? "CHECK_OUT_APPOINTMENT" : "CHECK_IN_APPOINTMENT") &&  
          hasPermission("ADD_STEPS_TO_A_JOURNEY") && !isMobile && (
          <Button
            width="max-content"
            isLoading={loading || checkOutLoading}
            onClick={isOngoingAppointment ? handleCheckOut : handleCheckIn}
            disabled={
              loading ||
              cancelAppointmentLoading ||
              patientLoading ||
              checkOutLoading ||
              isExpired
            }
          >
            {!isOngoingAppointment ? 'Check In' : 'Check Out'}
            <CheckInIcon />
          </Button>
        )}
        {hasPermission("CANCEL_APPOINTMENT") && !isMobile && (
          <Button
            width="max-content"
            onClick={handleCancel}
            background="neutral"
            color="black"
            isLoading={cancelAppointmentLoading}
            disabled={
              loading ||
              cancelAppointmentLoading ||
              patientLoading ||
              checkOutLoading ||
              isExpired
            }
          >
            Cancel Event
            <DisableIcon />
          </Button>
        )}
      </Row>
    )
  }

  return (
    <PaddingWrapper gap={1.25}>
      {appointmentLoading ? <Skeleton width="19.75rem" height={75} /> : (
        <>
          <Actions />
          <AppointmentText
            size="xs"
            weight="semibold"
            color={theme.grey[500]}
          >
            {`Created by ${staffGroup?.name} ${user?.firstName} ${user.lastName}`}
          </AppointmentText>
        </>
      )}
    </PaddingWrapper>
  );
};

export default AppointmentActions;
