import { H4, Text, Row, Button } from 'src/components';
import { font, theme } from 'src/utils/variables';
import styled from 'styled-components';

export const FullWidthForm = styled.form`
  width: 100%;
`;

export const FormTitle = styled(H4)`
  @media (max-width: 600px) {
    font-size: ${font.sizes.md};
  }
`;

export const RowWrap = styled(Row)`
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
    button {
      justify-self: flex-end;
    }
  }
`;

export const InfoTitle = styled(Text)`
  display: flex;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 1rem;
  color: ${theme.black[300]};
`;

export const InfoDescription = styled(Text)`
  font-weight: 400;
  font-size: 0.625rem;
  margin-top: 0;
`;

export const SubmitButton = styled(Button)`
  padding: 1rem 1.25rem;
  width: 9.4rem;
  @media (max-width: 960px) {
    width: 100%;
  }
`;
