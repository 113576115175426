import { Column, IconButton, Row } from 'src/components';
import { SidebarItemProps } from 'src/types';
import { theme } from 'src/utils/variables';
import styled, { css } from 'styled-components';

export const OverflowContainer = styled(Row)`
  overflow-y: auto;
  position: relative;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }
  scrollbar-width: none;
  position: relative;
`;

export const SwitchAccountButton = styled.button`
  padding: 0;
  border: 1px solid transparent;
  outline: 0;
  background: none;
  position: relative;
  cursor: pointer;
  img {
    padding: 0;
  }
  @media (max-width: 960px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;
    width: 100%;
  }
  @media (min-width: 960px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    width: 100%;
    border-radius: 0.625rem;
    max-width: 100%;
    & > svg,
    path {
      fill: ${theme.accent[700]};
    }
    &:hover {
      border: 1px solid ${theme.secondary[600]};
      background-color: white;
    }
  }
`;

export const SubGroupItemsWrapper = styled(Column)`
  z-index: 10;
  width: 12.1875rem;
  gap: 0.25rem;
  background-color: ${theme.white[200]};
  padding: 10px 12px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translate(215px, -65%);
  &:before {
    content: ' ';
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0;
    left: 0;
    transform: translateX(-20px);
    z-index: 20;
  }
  @media (max-width: 960px) {
    transform: translate(0px, 65%);
  }
`;

export const SidebarContainer = styled.nav<{ defaultOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 5.625rem;
  background-color: ${theme.white[200]};
  order: -1;
  transition: width 0.09s ease-in-out;
  padding: 1.25rem 1.3125rem;
  gap: 1.5rem;

  @media (min-width: 960px) {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100;
    span {
      display: none;
    }
    & > svg:first-child {
      display: flex;
      width: 2rem;
      height: 2rem;
    }
    button {
      ${Row} {
        display: none;
      }
    }
    ${SwitchAccountButton} {
      ${Column} {
        display: none;
      }
    }
    ${SubGroupItemsWrapper} {
      display: none;
    }
    ${({ defaultOpen }) =>
      defaultOpen
        ? css`
            width: 12.5rem;
            span {
              display: block;
              text-wrap: nowrap;
            }
            ${SubGroupItemsWrapper} {
              display: flex;
            }
            ${SwitchAccountButton} {
              ${Column} {
                display: flex;
              }
            }
            & > svg:first-child {
              align-self: flex-start;
            }
            hr {
              width: 100%;
            }
            button {
              justify-content: flex-start;
              padding: 0.625rem;
              width: 100%;
              ${Row} {
                display: flex;
                margin-left: auto;
                & > svg {
                  width: 0.5rem;
                  height: 0.5rem;
                  margin-left: 0;
                  display: flex;
                  path {
                    fill: ${theme.accent[700]};
                    stroke-width: 0;
                  }
                }
              }
            }
          `
        : css`
            &:hover {
              width: 12.5rem;
              span {
                display: block;
                text-wrap: nowrap;
              }
              ${SubGroupItemsWrapper} {
                display: flex;
              }
              ${SwitchAccountButton} {
                ${Column} {
                  display: flex;
                }
              }
              & > svg:first-child {
                align-self: flex-start;
              }
              hr {
                width: 100%;
              }
              button {
                justify-content: flex-start;
                padding: 0.625rem;
                width: 100%;
                ${Row} {
                  display: flex;
                  margin-left: auto;
                  & > svg {
                    width: 0.5rem;
                    height: 0.5rem;
                    margin-left: 0;
                    display: flex;
                    path {
                      fill: ${theme.accent[700]};
                      stroke-width: 0;
                    }
                  }
                }
              }
            }
          `}
  }

  @media print {
    display: none;
  }

  @media (min-width: 960px) {
    & > svg:first-child {
      min-width: 1.75rem;
      min-height: 1.75rem;
    }
  }

  @media (max-width: 960px) {
    flex-direction: row;
    height: max-content;
    z-index: 100;
    width: 100vw;
    padding: 1.25rem 1rem;
    overflow-y: hidden;
    overflow-x: auto;
    order: 0;
    align-self: center;
    position: fixed;
    bottom: 0;
  }
`;

export const SidebarItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  height: fit-content;
  width: 100%;
  gap: 1rem;
  @media (max-width: 960px) {
    height: max-content;
    flex-direction: row;
    width: 100%;
    gap: 0.5rem;
    flex: 1;
    justify-content: space-between;
  }
`;

export const SidebarItem: React.FC<SidebarItemProps> = styled(IconButton)`
  display: ${(props) => (!props.hide ? 'flex' : 'none')};
  position: relative;
  width: 3rem;
  border: 1px solid transparent;
  height: 3rem;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: start;
    padding: 10px;
    height: 2.5rem;
    gap: 0.625rem;
    span {
      color: inherit;
      font-size: 0.875rem;
    }
    svg,
    path {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  @media (min-width: 960px) {
    width: 2.5rem;
    height: 2.5rem;
    gap: 0.625rem;
    span {
      color: inherit;
      font-size: 0.875rem;
    }
    svg,
    path {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  svg,
  path {
    width: 1rem;
    height: 1rem;
    stroke: ${(props) => !props.noStroke && theme.primary[500]};
  }
  ${({ active, noFill, noStroke }: SidebarItemProps) =>
    active &&
    css`
      background-color: ${theme.secondary[700]};
      color: ${theme.white[100]};
      svg,
      path {
        ${!noStroke &&
        css`
          stroke: ${theme.white[100]};
        `}
        ${!noFill &&
        css`
          fill: ${theme.white[100]};
        `}
      }
    `}

  &:hover {
    background-color: ${theme.white[100]};
    border: 1px solid ${theme.secondary[600]};
    color: ${theme.grey[600]};
    svg,
    path {
      ${({ noStroke }) =>
        !noStroke &&
        css`
          stroke: ${theme.grey[600]};
        `}

      ${({ noFill }) =>
        !noFill &&
        css`
          fill: ${theme.grey[600]};
        `}
    }
  }
`;

export const HR = styled.hr`
  width: 70%;
  border: 0.5px solid ${theme.grey[200]};
  @media (max-width: 960px) {
    width: 100%;
    margin-block: 0.5rem;
  }
`;
