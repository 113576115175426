/* eslint-disable no-underscore-dangle */
import { Input, Select } from 'src/components';
import { useContext, useEffect, useRef } from 'react';
import { getFormErrorMessage, theme } from 'src/utils';
import { DeleteIcon } from 'src/assets/icons';
import { Invoice, InvoiceItem } from 'src/types';
import { FormikErrors } from 'formik';
import { MultiRowDeleteButton } from 'src/containers/MultiRowForm/styled';
import {
  TableCell,
  Multiplication,
} from 'src/pages/admin/PatientDetails/Invoice/styled';
import {
  ADD_PRODUCT_INVOICE_ITEM,
  DELETE_INVOICE_ITEM,
  GET_INVOICES_TOTAL,
  UPDATE_INVOICE_ITEM,
} from 'src/constants';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useStaff } from 'src/state';
import mixpanel from 'mixpanel-browser';
import useMediaQuery from 'src/utils/useMediaQuery';
import { inputHeaders } from 'src/pages/admin/PatientDetails/Invoice/constants';
import { Divider } from 'src/modals/ViewAppointment/styled';
import {
  InvoiceType,
  defaultLoadingRow,
  dummyItem,
  extractRequiredFields,
  isItemValid,
} from '../invoice/OpenInvoice';
import { ViewContext } from '../invoice/index';

export interface RowInputGroupType
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  arrIdx: number;
  item: InvoiceItem;
  handleRowInputClick: () => void;
  loading: typeof defaultLoadingRow;
  setLoading: React.Dispatch<React.SetStateAction<typeof defaultLoadingRow>>;
  setNetworkErrors: React.Dispatch<
    React.SetStateAction<Record<number, string>>
  >;
  networkErrors: Record<number, string>;
  invoiceType: InvoiceType;
  isWritesBlocked: boolean;
  setIsWritesBlocked: React.Dispatch<React.SetStateAction<boolean>>;
  errors?: FormikErrors<{
    invoice: {
      items: InvoiceItem[];
    };
  }>;
}

const RowInputGroup: React.FC<RowInputGroupType> = ({
  item,
  arrIdx,
  handleRowInputClick,
  networkErrors,
  setNetworkErrors,
  errors,
  loading,
  setLoading,
  invoiceType,
  isWritesBlocked,
  setIsWritesBlocked,
  ...props
}) => {
  const { currentInvoice, setCurrentInvoice, patient } =
    useContext(ViewContext);
  const isMobile = !useMediaQuery('(min-width: 960px)');
  const { staff } = useStaff();

  const [deleteInvoiceItem] = useMutation(DELETE_INVOICE_ITEM);

  const isItemLoading = loading.loading && arrIdx === loading.idx;
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const items = [..._.cloneDeep(currentInvoice.items)];
    const currentItem = items[arrIdx];
    const { value, name, type } = e.target;
    const numberValue = value.length === 0 ? undefined : Number(value);
    currentItem[name] = type !== 'number' ? value : numberValue;

    if (['units'].includes(name)) {
      const nErrors = _.cloneDeep(networkErrors);
      delete nErrors[arrIdx];
      setNetworkErrors(nErrors);
      currentItem.totalCost =
        Number(currentItem.unitCost || 0) * Number(value || 1);
    }
    if (['unitCost'].includes(name)) {
      currentItem.totalCost =
        Number(currentItem.units || 1) * Number(value || 0);
    }
    if (isItemValid(currentItem)) {
      currentItem.itemUpdateQueued = true;
    }
    items[arrIdx] = currentItem;
    setCurrentInvoice((prev) => ({
      ...prev,
      items,
    }));
  };

  const deleteServiceRow = async (arrPos: number, dbIdx?: string) => {
    mixpanel.track(`Click 'Delete Invoice Item' button`, {
      feature: 'Inventory Invoice',
    });
    startAddDebounce.current.cancel();
    const items = [..._.cloneDeep(currentInvoice.items)];
    items.splice(arrPos, 1);
    if (currentInvoice?.items.length === 1) {
      setCurrentInvoice((prev) => ({
        ...prev,
        items: [{ ...dummyItem }],
      }));
    } else {
      setCurrentInvoice((prev) => ({
        ...prev,
        items,
      }));
    }
    const nErrors = _.cloneDeep(networkErrors);
    delete nErrors[arrPos];
    setNetworkErrors(nErrors);
    // delete db reference
    if (dbIdx) {
      setLoading({ idx: arrIdx, loading: true });
      await deleteInvoiceItem({
        variables: {
          invoiceItemId: dbIdx,
        },
        onCompleted() {
          setLoading({ idx: -1, loading: false });
        },
      });
    }
  };

  const [addProductItem, { error: addProductItemError }] = useMutation(
    ADD_PRODUCT_INVOICE_ITEM,
    { errorPolicy: 'all' },
  );

  const [updateInvoiceItem] = useMutation(UPDATE_INVOICE_ITEM);

  const saveItem = async (invoiceItem: InvoiceItem, currInv: Invoice) => {
    const { isHMOPayable, _id, productCode, batchNo } = invoiceItem;
    const newItem: InvoiceItem = extractRequiredFields(invoiceItem);
    if (isItemValid(newItem)) {
      setLoading({ idx: arrIdx, loading: true });
      const input = {
        ...newItem,
        batchNo,
        productCode,
        currency: 'NGN',
        isHMOPayable: isHMOPayable === 'HMO',
        invoiceType: invoiceType.toUpperCase(),
      };

      if (!_id) {
        mixpanel.track(`Added Invoice Item`, { feature: 'Inventory Invoice' });
        await addProductItem({
          variables: {
            input,
            ...(currInv?._id ? { invoiceId: currInv?._id } : {}),
          },
          refetchQueries: !currInv._id ? [GET_INVOICES_TOTAL] : [],
          onCompleted(data) {
            if (!addProductItemError) {
              const { item: dbItem, invoice } =
                data?.addProductInvoiceItem || {};

              if (isWritesBlocked && invoice?._id) {
                setIsWritesBlocked(false);
              }
              setCurrentInvoice((prev) => {
                prev.items[arrIdx]._id = dbItem?._id;
                return {
                  ...prev,
                  _id: invoice?._id,
                  invoiceId: invoice?.invoiceId,
                  createdAt: invoice?.createdAt,
                };
              });
              if (!item.updateQueued) setLoading({ idx: -1, loading: false });
              // setTimeout(() => setLoading({ idx: -1, loading: false }), 500);
            }
          },
          onError() {
            setLoading({ idx: -1, loading: false });
          },
        });
      } else {
        mixpanel.track(`Updated Invoice Item`, {
          feature: 'Inventory Invoice',
        });
        const dt = await updateInvoiceItem({
          variables: {
            invoiceItemId: _id,
            input,
          },
          errorPolicy: 'all',
          onCompleted() {
            if (!item.updateQueued) setLoading({ idx: -1, loading: false });
          },
        });
        if (dt.errors) {
          setNetworkErrors((prev) => {
            prev[arrIdx] = dt.errors?.[0]?.message as string;
            return prev;
          });
        } else {
          setNetworkErrors((prev) => {
            delete prev[arrIdx];
            return prev;
          });
        }
      }
    }
  };

  const startAddDebounce = useRef(_.debounce(saveItem, 0));
  const startUpdateDebounce = useRef(_.debounce(saveItem, 1500));

  useEffect(() => {
    if (!currentInvoice._id && !isWritesBlocked && item.itemAddQueued) {
      setLoading((prev) => ({ ...prev, loading: true }));
      setIsWritesBlocked(true);
      startAddDebounce.current(item, currentInvoice);
    }
    if (item.itemAddQueued && currentInvoice._id && !item._id) {
      setLoading((prev) => ({ ...prev, loading: true }));
      startAddDebounce.current(item, currentInvoice);
      const items = [..._.cloneDeep(currentInvoice.items)];
      items[arrIdx].itemAddQueued = false;
      setCurrentInvoice((prev) => ({
        ...prev,
        items,
      }));
    }
    if (item.itemUpdateQueued && item._id) {
      setLoading((prev) => ({ ...prev, loading: true }));
      startUpdateDebounce.current(item, currentInvoice);
      const items = [..._.cloneDeep(currentInvoice.items)];
      items[arrIdx].itemUpdateQueued = false;
      setCurrentInvoice((prev) => ({
        ...prev,
        items,
      }));
    }
  }, [
    arrIdx,
    currentInvoice,
    currentInvoice._id,
    isWritesBlocked,
    item,
    networkErrors,
    setCurrentInvoice,
    setIsWritesBlocked,
    setLoading,
  ]);

  return (
    <tr {...props} className='row-wrap' data-testid="row-input-group">
      <TableCell width={isMobile ? "50%" : "20%"} modStyles={{ pr: isMobile ? 0 : 0.5 }}>
        <Input
          placeholder="description"
          data-testid="item-description"
          name="description"
          type="text"
          label={isMobile ? inputHeaders[0] : undefined}
          value={item.description}
          onChange={handleChange}
          onClick={() => handleRowInputClick()}
          disabled={
            // !hasPermission('ADD_ITEMS_TO_PATIENT_INVOICE') ||
            Boolean(item._id) &&
            Boolean(item?.addedBy?.id) &&
            staff.getStaff.id !== item?.addedBy?.id &&
            !['Owner', 'Admin'].includes(staff.getStaff.staffGroup.name)
          }
          error={getFormErrorMessage(
            errors,
            `invoice.items.${arrIdx}.description`,
          )}
          readOnly
        />
      </TableCell>
      <TableCell width={isMobile ? "50%" : "25%"} modStyles={{ pr: isMobile ? 0 : 0.5, pl: 0.5 }}>
        <Select
          placeholder="Payment Mode"
          data-testid="item-hmo-payable"
          name="isHMOPayable"
          label={isMobile ? inputHeaders[1] : undefined}
          onChange={handleChange}
          value={item.isHMOPayable as string}
          disabled={
            // !hasPermission('ADD_ITEMS_TO_PATIENT_INVOICE') ||
            Boolean(item._id) &&
            Boolean(item?.addedBy?.id) &&
            staff.getStaff.id !== item?.addedBy?.id &&
            !['Owner', 'Admin'].includes(staff.getStaff.staffGroup.name)
          }
        >
          <option value="OOP">Out Of Pocket</option>
          {patient?.hmo?.hmo.id && <option value="HMO">HMO Payable</option>}
        </Select>
      </TableCell>
      <TableCell width={isMobile ? "50%" : "20%"} modStyles={{ px: isMobile ? 0 : 0.5 }}>
        <Input
          placeholder="units"
          data-testid="item-units"
          name="units"
          label={isMobile ? inputHeaders[2] : undefined}
          type="number"
          min={1}
          value={item.units}
          onChange={handleChange}
          disabled={
            // !hasPermission('ADD_ITEMS_TO_PATIENT_INVOICE') ||
            Boolean(item._id) &&
            Boolean(item?.addedBy?.id) &&
            staff.getStaff.id !== item?.addedBy?.id &&
            !['Owner', 'Admin'].includes(staff.getStaff.staffGroup.name)
          }
          error={
            getFormErrorMessage(errors, `invoice.items.${arrIdx}.units`) ||
            networkErrors[arrIdx]
          }
        />
      </TableCell>
      {!isMobile && (
        <TableCell modStyles={{ px: 0.5 }}>
          <Multiplication>X</Multiplication>
        </TableCell>
      )}
      <TableCell width={isMobile ? "40%" : "25%"} modStyles={{ px: 0.5 }}>
        <Input
          placeholder="₦5000"
          data-testid="item-unit-cost"
          label={isMobile ? inputHeaders[4] : undefined}
          name="unitCost"
          type="number"
          value={item.unitCost}
          onChange={handleChange}
          disabled={
            // !hasPermission('ADD_ITEMS_TO_PATIENT_INVOICE') ||
            Boolean(item._id) &&
            Boolean(item?.addedBy?.id) &&
            staff.getStaff.id !== item?.addedBy?.id &&
            !['Owner', 'Admin'].includes(staff.getStaff.staffGroup.name)
          }
          error={getFormErrorMessage(
            errors,
            `invoice.items.${arrIdx}.unitCost`,
          )}
          readOnly
        />
      </TableCell>
      <TableCell width={isMobile ? "10%" : "max-content"} modStyles={{ pl: isMobile ? 0 : 0.5, mt: isMobile ? 2 : 0 }}>
        <MultiRowDeleteButton
          variant="solid"
          data-testid="delete-invoice-item"
          background={theme.grey[100]}
          color={theme.grey[200]}
          size="2.5rem"
          type="button"
          onClick={() => deleteServiceRow(arrIdx, item?._id)}
          disabled={
            // !hasPermission('ADD_ITEMS_TO_PATIENT_INVOICE') ||
            Boolean(item._id) &&
            Boolean(item?.addedBy?.id) &&
            staff.getStaff.id !== item?.addedBy?.id &&
            !['Owner', 'Admin'].includes(staff.getStaff.staffGroup.name)
          }
          isLoading={!item._id && isItemLoading}
        >
          <DeleteIcon />
        </MultiRowDeleteButton>
      </TableCell>
      {isMobile && <Divider style={{ marginBottom: "1rem" }} />}
    </tr>
  );
};

export default RowInputGroup;
