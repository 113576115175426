import { ArrowForwardIcon } from 'src/assets/icons';
import { Row } from 'src/components';
import { useStaff, useFeatureFlag } from 'src/state';
import { SidebarConfig } from 'src/types';
import { checkPermission, openModal } from 'src/utils';
import useMediaQuery from 'src/utils/useMediaQuery';
import { useLocation } from 'wouter';
import mixpanel from 'mixpanel-browser';
import { SidebarItem } from './styled';

interface SideBarButtonItemProps {
  item?: SidebarConfig;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const SideBarButtonItem: React.FC<SideBarButtonItemProps> = ({
  item,
  onClick,
  children,
}) => {
  const [location, setLocation] = useLocation();
  const { staffPermissions } = useStaff();
  const isMobile = !useMediaQuery('(min-width: 960px)');
  const { feats } = useFeatureFlag();

  const {
    noFill,
    noStroke,
    title,
    path,
    subGroup,
    action,
    icon: Icon,
    permission,
    showOnMobile,
  } = (item || {}) as SidebarConfig;
  const isActive =
    !!(path && location.indexOf(path) > -1) ||
    (subGroup?.group &&
      subGroup.group.some(
        (subItem) => subItem.path && location.includes(subItem.path),
      ));

  return (
    <SidebarItem
      label={title}
      variant={isActive ? 'solid' : 'text'}
      active={isActive}
      noFill={noFill}
      noStroke={noStroke}
      data-testid={title}
      data-for="sidebar-tooltip"
      data-tip={title}
      data-iscapture="true"
      hide={
        (feats && feats[title] && !feats[title].enabled) ||
        !checkPermission(staffPermissions, permission) ||
        (isMobile && showOnMobile === false)
      }
      color="grey"
      noIconStyles
      className={title?.split(' ').join('-').toLowerCase()}
      onClick={() => {
        onClick?.();
        if (location.includes(path as string)) return;
        if (action) action();
        if (path) {
          const isModal = path.includes('#');
          mixpanel.track(`Visited ${path}`, { feature: 'Sidebar' });
          if (isModal) openModal(path.replace('#', ''));
          else setLocation(path);
        }
      }}
    >
      {Icon && <Icon />}
      {title && <span>{title}</span>}
      {children}
      {!isMobile && subGroup && (
        <Row justify="center" align="center" width="1.25rem" height="1.25rem">
          <ArrowForwardIcon />
        </Row>
      )}
    </SidebarItem>
  );
};
