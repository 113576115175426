import { theme } from 'src/utils/variables';
import styled from 'styled-components';
import { Row } from '..';


export const NavigationContainer = styled(Row)`
  position: absolute;
  width: max-content;
  max-height: max-content;
  top: 0;
  z-index: 999;
  background-color: ${theme.white[200]};
  border-radius: 0.625rem;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
  padding: 1rem 0.625rem;
  label {
    margin: 0;
  }
`;
export const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-left: auto;
`;

export const PopoverContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-300px'};
  width: 200px;
  background-color: ${theme.white[200]};
  border-radius: 0.625rem;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
  padding: 1rem 0.625rem;
  transition: right 0.3s ease;
  z-index: 1000;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  color: #666;
  cursor: pointer;

  &:hover {
    color: #333;
  }
`;

export const MenuText = styled.span`
  margin-left: 12px;
`;

export const UserProfile = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding: 8px 0;
  justify-content: space-between;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`;
