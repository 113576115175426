import React from 'react';
import { ArrowDown } from 'src/assets/icons';
import { DropdownProps } from 'src/types';
import useClickAway from 'src/utils/useClickAway';
import { theme } from 'src/utils/variables';

import {
  DropdownTrigger,
  DropdownContainer,
  DropdownList,
  DropdownListItem,
} from './styled';

export const Dropdown: React.FC<DropdownProps> = ({
  width,
  title,
  options,
  name,
  position,
  icon,
  display,
  dataTestId,
  labelComponent,
  triggerComponent,
  handleDropdownChange,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickAway(false);

  const handleSelect = (value: string) => {
    if (handleDropdownChange) {
      handleDropdownChange(name, value);
    }
    setIsComponentVisible(false);
  };
  return (
    <DropdownContainer display={display} width={width || 'max-content'} ref={ref}>
      {triggerComponent ? (
        triggerComponent(() => setIsComponentVisible(!isComponentVisible))
      ) : (
        <DropdownTrigger
          background={theme.grey[100]}
          onClick={() => setIsComponentVisible(!isComponentVisible)}
          aria-expanded={isComponentVisible}
          icon={Boolean(icon)}
          data-testid={dataTestId}
        >
          {icon && icon}
          {title && <span>{title}</span>}
          {!title && (
            <span>{options?.find(({ checked }) => checked)?.label}</span>
          )}
          {!icon && <ArrowDown />}
        </DropdownTrigger>
      )}

      {isComponentVisible && options && options?.length > 0 && (
        <DropdownList position={position}>
          {options.map(({ value, label, checked }, idx) => {
            return (
              <DropdownListItem
                key={`${value}-${idx}`}
                data-testid={label.toLowerCase()}
                active={checked as boolean}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelect(value);
                }}
              >
                {labelComponent?.(label, idx) || <span>{label}</span>}
              </DropdownListItem>
            );
          })}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};
